.filter-dropdown {
  flex-direction: column;
  inset: 30px auto auto 18px !important;
  li{
    background-color: white;
    z-index: 9999999 !important;
  }
}
.dropdown-divider{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.z-index-99 {
  z-index: 99 !important;
}