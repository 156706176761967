.custom-title {
  line-height: 28px;
  min-width: max-content;
}
.table-ds-thuoc {
  height: calc(100vh - 470px);
}
.wf-tu-truc {
  height: 34px;
}

.max-content {
  min-width: max-content;
}

div.themphieubosungcosotutruc-columnsColumns-1.col-lg-9 > div {
  padding-left: 0 !important;
}
div.themphieubosungcosotutruc-columns2Columns-1.col-lg-11 > div {
  padding-left: 12px !important;
}

@media screen and (max-width: 1280px) {
  div.themphieubosungcosotutruc-columns2Columns-1.col-lg-11 > div {
    padding-left: 7px !important;
  }
  div.themphieubosungcosotutruc-columnsColumns3-1.col-lg-10 > div,
  div.themphieubosungcosotutruc-columns2Columns2-1.col-lg-10 > div > div > div,
  div.themphieubosungcosotutruc-columns3ColumnsColumns2-1.col-lg-10 > div > div > div {
    padding-left: 10px !important;
  }
  div.themphieubosungcosotutruc-columns5ColumnsColumns3-1.col-lg-2 > div > div > div {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 992px) {
  div.themphieubosungcosotutruc-columns2Columns-1.col-lg-11 > div {
    padding-left: 0px !important;
  }
  div.themphieubosungcosotutruc-columnsColumns3-1.col-lg-10 > div,
  div.themphieubosungcosotutruc-columns2Columns2-1.col-lg-10 > div > div > div,
  div.themphieubosungcosotutruc-columns3ColumnsColumns2-1.col-lg-10 > div > div > div {
    padding-left: 0px !important;
  }
  div.themphieubosungcosotutruc-columns5ColumnsColumns3-1.col-lg-2 > div > div > div {
    margin-left: 0px !important;
  }
}

.border-custom-input {
  border: 1px solid #b0b0b0;
}
