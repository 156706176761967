@import "../../styles/variable";

#kt_table_custom_select {
    tr {
        &:hover {
            background-color: #e6f7ff;
        }

        th {
            background-color: #3C78D8 !important;
            color: #ffffff !important;
            &:hover {
                background-color: #3C78D8 !important;
            }
        }
    }
}

.table-responsive {
    max-height: 500px;
    overflow-y: scroll !important;
}