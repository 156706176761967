.h-bang-ds-phieu {
  .table-responsive {
    max-height: max-content;
  }
  height: calc(100vh - 210px) !important;
}

.h-bang-ds-xuat-kho {
  height: calc(100vh - 450px) !important;
}

.table-view-ds-thuoc {
  height: calc(100vh - 454px);
}

.title-info-export {
   display: block;
   min-width: 105px;
   font-weight: 500;
   font-size: 1.1rem;
 }