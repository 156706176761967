div.onlyAcceptNumber {
  padding: 2px;
  border: 2px dashed #ccc;
  cursor: text;
  min-height: 30px;
  background-color: #f9f9f9;
  position: relative;
  &::focus {
    outline: none;
  }
  &[contenteditable="false"]{
    border: none;
    background-color: transparent;
  }
}

div.themPhieuXuatSuDung-columnsColumns-1.col-lg-9 > div {
  padding-left: 0 !important;
}
div.themPhieuXuatSuDung-columns2Columns-1.col-lg-11 > div {
  padding-left: 12px !important;
}
div.themPhieuXuatSuDung-columns6Columns-1.col-lg-11 > div {
  padding-left: 32px !important;
}

@media screen and (max-width: 1280px) {
  div.themPhieuXuatSuDung-columns2Columns-1.col-lg-11 > div {
    padding-left: 7px !important;
  }
  div.themPhieuXuatSuDung-columns6Columns-1.col-lg-11 > div {
    padding-left: 27px !important;
  }
  div.themPhieuXuatSuDung-columnsColumns3-1.col-lg-10 > div,
  div.themPhieuXuatSuDung-columns2Columns2-1.col-lg-10 > div > div > div,
  div.themPhieuXuatSuDung-columns3ColumnsColumns2-1.col-lg-10 > div > div > div {
    padding-left: 10px !important;
  }
  div.themPhieuXuatSuDung-columns5ColumnsColumns3-1.col-lg-2 > div > div > div {
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 992px) {
  div.themPhieuXuatSuDung-columns2Columns-1.col-lg-11 > div {
    padding-left: 0px !important;
  }
  div.themPhieuXuatSuDung-columns6Columns-1.col-lg-11 > div {
    padding-left: 0px !important;
  }
  div.themPhieuXuatSuDung-columnsColumns3-1.col-lg-10 > div,
  div.themPhieuXuatSuDung-columns2Columns2-1.col-lg-10 > div > div > div,
  div.themPhieuXuatSuDung-columns3ColumnsColumns2-1.col-lg-10 > div > div > div {
    padding-left: 0px !important;
  }
  div.themPhieuXuatSuDung-columns5ColumnsColumns3-1.col-lg-2 > div > div > div {
    margin-left: 0px !important;
  }
}

.table-ds-tong-hop-du-tru {
  .table-responsive {
    min-height: calc(100vh - 210px);
  }
}

.h-calc-vh-200px {
  height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}

.h-calc-vh-270px {
  height: calc(100vh - 270px);
  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
}
