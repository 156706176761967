@import '../../styles/variable';

.custom-tooltip {
    position: absolute;

    .tooltip-inner {
        border-radius: 5px;
        padding: 4px 6px !important;
        background-color: $color-steel-blue !important;
        color: #fff !important;
    }

    &.tooltip-error {
        .tooltip-inner {
            border-radius: 5px;
            padding: 4px 6px !important;
            background-color: white !important;
            color: $color-error !important;
            border: 1px solid $color-error !important;
        }
    }

    .tooltip-arrow {
        display: none;
    }
}