.delete {
  i {
    color: red;
    font-size: 1rem;
    cursor: pointer;
  }
}

.edit {
  i {
    color: #009ef7;
    font-size: 1rem;
    cursor: pointer;
  }
}

.disabed-icon {
  i {
    color: #6c757d !important;
    cursor: not-allowed !important;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    white-space: nowrap;
    background-color: #fff;
    text-align: center;
    border: 1px solid #a4c6d4;
    border-radius: 6px;
    padding: 2px 4px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 13px;
    font-weight: 400;
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
