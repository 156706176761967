@import "../styles/variable";

.form-ds-goi-thau {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 490px);
}

.form-them-moi-thau {
  margin-top: 5px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 432px);
}


.h-bang-vt-thau {
  height: calc(100vh - 485px);
}

.h-bang-ds-thau {
  min-height: 180px;
  height: calc(100vh - 550px);
  max-height: 300px;
}
.h-180px {
  height: 180px;
}

.w-form-add {
  width: calc(200vh - 10px) !important;
}
// .table-responsive {
//   height: calc(100vh - 385px) !important;
// }