@import "variable";
.heading-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.heading-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.01em;
}

.heading-3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
}

.heading-4 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
}

.heading-5 {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
}

.supertitle {
  font-style: normal;
  font-weight: 400;
  font-size: $default-font-size;
  line-height: 120%;
  /* identical to box height, or 17px */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
}

.body-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
}

.body-normal-1 {
  font-style: normal;
  font-weight: 400;
  font-size: $default-font-size;
  line-height: 145%;
}

.body-normal-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
}

.body-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 155%;
}

.body-bold-1 {
  font-style: normal;
  font-weight: 700;
  font-size: $default-font-size;
  line-height: 145%;
}

.body-bold-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 145%;
}

.font-control {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 127%;
}

.font-control-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Light.ttf') format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Regular.ttf') format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Italic.ttf') format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Medium.ttf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-LightItalic.ttf') format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Bold.ttf') format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-style: italic;
}