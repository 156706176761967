@import "../styles/variable";

.form-thong-ke {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 500px);
}

.form-them-moi-vat-tu {
  margin-top: 5px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 395px);
}

.title-color-ttc {
  background-color: #17a2b8;
  color: white !important;
  padding: 0px 30px;
  line-height: 28px;
}

.title-back {
  color: #3C78D8;
  border: 1px solid #3C78D8;
  border-radius: 4px;
}

.tree {
  border: 1px solid #cecece;
  border-left: none;
  line-height: 21px;
  width: 25%;
  font-size: $default-font-size;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 229px);

  .quantity {
    border: 1px solid #17a2b8;
    border-radius: 5px;
    color: #fff;
    color: #17a2b8;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    margin: 2.45px 0;
    padding: 2px;
    text-align: center;
    width: 16px;
    line-height: 10.5px;
  }
}

.isSelected {
  color: white;
  background: #42beb1;
  border-radius: 0.475rem;
  background-color: #f6f6f6;
  border: 1px solid #c9bfb3;
  padding: 6px;
}

.h-70px {
  min-height: 70.5px !important;
}

.isFilterSelected {
  width: 93%;
  color: $color-text-cyan;
  background: #e6fffb;
  // border-right: #08979c 3px solid;
  line-height: 21px;
  // padding: 10px;
}

#kt_table_users {
  tr {
    &:hover {
        background-color: #e6f7ff;
    }

    th {
        background-color: #3C78D8 !important;
        color: #ffffff !important;
        &:hover {
            background-color: #3C78D8 !important;
        }
    }
}
}

.hover-row {
  &:hover {
    width: 93%;
    color: $color-text-cyan;
    background: #e6fffb;
    // border-right: #08979c 3px solid;
    line-height: 21px;
  }

  line-height: 21px;
  width: 93%;
}

.h-bang-ds-vt {
  min-height: 300px;
  height: calc(100vh - 180px) !important;
  // max-height: 400px
}

.w-form-add {
  width: calc(200vh - 10px) !important;
}

.h-form-add-ttt {
  // height: calc(100vh - 140px)
}