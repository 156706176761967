#table-du-tru-bo-sung-vt {
  .table-responsive {
    min-height: calc(100vh - 230px) !important;
    max-height: calc(100vh - 230px) !important;
  }
  .table-toolbar {
    padding: 0px !important;
  }
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: auto;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#table-lst-item-du-tru-bo-sung-vt {
  .table-responsive {
    min-height: calc(100vh - 245px) !important;
    max-height: calc(100vh - 245px) !important;
  }
}