@import "../../../styles/variable";



.title-back {
  color: #3C78D8;
  border: 1px solid #3C78D8;
  border-radius: 4px;
}

.title {
  color: #3C78D8;
  font-family: Roboto, "sans-serif";
  font-weight: 700;
  font-size: 14;
}

.footer-buttom {
  position: fixed;
  bottom:0
}

.btn-file {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  opacity: 0;
}
