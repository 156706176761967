.border-table {
  border-top: 7px solid #F0F2F5;
}

.custom-table {
  &:hover {
    .custom-button {
      display: flex !important;
      animation: slideDown 0.4s ease-out forwards;
    }
  }
}

.custom-button {
  display: none;
  z-index: 9;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 27px;
  cursor: pointer;
  i {
    padding-left: 10px;
    font-size: 14px;
  }

  &:not(:hover) {
    animation: none;
  }
}


.modal-body {
  overflow-y: visible !important;
}

.modal-content {
  overflow-y: visible !important;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-summary {
  font-weight: 700;
  .quantity {
    color: #17A2B8;
    font-weight: 400;
    padding-left: 20px;
  }

  &.color-summary {
    color: #DC3545;
    padding-right: 100px;
  }

  .money {
    padding-left: 20px;
  }
}

.btn-error {
  background-color: #DC3545 !important;
  &:hover {
    background-color: #ff4d5f !important;
  }
}

.table-dieu-chuyen {
  .table-responsive {
    max-height: none;
  }
  .customs-collapse-row {
    height: calc(100vh - 510px) !important;
  }
}

.table-view-dieu-chuyen {
  .table-responsive {
    max-height: none;
  }
  .customs-collapse-row {
    height: calc(100vh - 440px) !important;
  }
}

.table-wait-import {
  .table-responsive {
    max-height: none;
  }
  .customs-collapse-row {
    height: calc(100vh - 470px) !important;
  }
}

.table-transfer {
  .customs-collapse-row {
    height: calc(100vh - 295px) !important;
  }
  .table-responsive {
    max-height: 1000px;
  }
}

.min-h-calc-vh-230px {
  min-height: calc(100vh - 230px);
};

.title-info-transfer {
  display: block;
  min-width: 90px;
  font-weight: 500;
  font-size: 1.1rem;
}