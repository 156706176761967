:root {
    --bs-danger-rgb: 241, 65, 108;
    --bs-btn-primary: #3C78D8;
    --bs-btn-secondary: #6c757d;
    --bs-btn-primary-hover: #17a2b8;
    --bs-btn-outline-hover: #e6fffb;
    --color-primary: #3C78D8;
    --color-header-menu: #ffc107;
    --color-header-app-container: #343a40;
    --color-linear-green: linear-gradient(180deg, #0E9594 0%, #127475 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    --color-linear-teal: linear-gradient(180deg, #50c5b5 0%, #13a7a4 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    --color-linear-navy: linear-gradient(180deg, #1e7a9e 0%, #2789af 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    --color-header-table: #ced4da;
    --color-header-table-custom: #3C78D8;
}

$color-teal: #57C5B6;
$color-teal-active-hover: #B3E8E5;
$color-green: #159895;
$color-teal-navy-blue: #2789af;
$color-navy-blue: #002B5B;
$color-hover: #0788ba;
$color-bg-hover: #f3f3f3;
$color-error: #f1416c;
$color-steel-blue: #17A2B8; // border, text cho bộ lock table 
$color-blue: #57C5B6;
$color-blue-blur: #519DD9;
$color-dark: #111111;
$color-dark-blur-1: #bebebe;
$color-dark-blur-2: #CFCFCF;
$color-gray-1: #f1f3f5;
$color-white: #FFFFFF;
$color-border-table: #d6dddd;
$color-color-table-header: #68a3ce;
$color-disabled-bg: #eff2f5;
$color-disabled-text: #c0c0c0;
$color-border-gray: #b0b0b0;
$color-turquoise: #17A2B8;

$color-text-cyan: #3C78D8;
$icon-color: #ffc107;
$default-font-size: 1.1rem;
$modal-title-fs: 1.23rem;
$bs-border-color: #f1f3f5;
$bg-blue: #17A2B8;
$bg-button-disabled: #6C757D;