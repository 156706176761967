.hyperlink {
  color: #008cba;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.height-29 .css-5asp8r-control {
  height: 29px;
  min-height: 29px;
}

// .autocomplete-custom {
//   span {
//       &+div {
//           display: flex;
//           max-height: 22px;
//           align-items: center;
//       }
//   }
//   &>div {
//       padding-right: 0;
//       border-radius: 3px;
//   }

//   &.rounded-0 > div {
//       border-radius: 0;
//   }
//   &.radius > div {
//       border-radius: 3px;
//   }
// }
.autocomplete-custom-renderform {
  span {
      &+div {
          display: flex;
          max-height: 22px;
          align-items: center;
      }
  }
  &>div {
      padding-right: 0;
      border-radius: 3px;
      border-color: #b0b0b0;
  }

  &.rounded-0 > div {
      border-radius: 0;
  }
  &.radius > div {
      border-radius: 3px;
      border-color: #b0b0b0;
  }
}
.text-field-label-down-line {
  display: block;
  align-items: center;
  margin: 0px 3px 0px 3px;
}
.max-w-125px {
  max-width: 125px;
}
.min-height-90px {
  min-height: 90px !important;
}
.generate-form-border-bottom {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
  padding-bottom: 10px;
}
.thongTinChung-columnsColumns15-1 {
  max-width: 89.6%;
}
.thongTinChung-columnsColumns15-0 {
  min-width: 10.4%;
}
.thongTinChung-columnsColumns16-0 {
  min-width: 20.8%;
}
.thongTinChung-columnsColumns16-1 {
  max-width: 79.2%;
}