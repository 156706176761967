.h-bang-ds-phieu {
  height: calc(100vh - 210px) !important;
}
.h-bang-ds-nhap-kho {
  height: calc(100vh - 420px) !important;
}
.border-table {
  border-top: 7px solid #F0F2F5;
}

.custom-table {
  &:hover {
    .custom-button {
      display: flex !important;
      animation: slideDown 0.4s ease-out forwards;
    }
  }
}

.custom-button {
  display: none;
  z-index: 9;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 27px;
  cursor: pointer;
  i {
    padding-left: 10px;
    font-size: 14px;
  }

  &:not(:hover) {
    animation: none;
  }
}


.modal-body {
  overflow-y: visible !important;
}

.modal-content {
  overflow-y: visible !important;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bottom-button {
  position: fixed;
  bottom: 0px;
}

.table-tt-nhap-thuoc { 
  .table-responsive {
    max-height: none;
    height: calc(100vh - 500px) !important;
  }
}

.table-view-tt-nhap-thuoc { 
  .table-responsive {
    height: calc(100vh - 470px) !important;
  }
}

.title-info-import {
  display: block;
  min-width: 97px;
  font-weight: 500;
  font-size: 1.1rem;
}