.title-back {
  color: #3C78D8;
  border: 1px solid #3C78D8;
  border-radius: 4px;
}
.title {
  color: #3C78D8;
  font-family:  Roboto,"sans-serif";
  font-weight: 700;
  font-size: 14;
}

.form-them-vat-tu-xk {
  position: relative;

  .custom-position-btn {
    margin-top:10px;
    bottom: 8px;
    right: 10px;
  }
}

.table-bang-ds-xuat-thuoc {
  .table-responsive {
    max-height: none;
    height: calc(100vh - 450px) !important;
    #kt_table_oct {
      th {
        background-color: #3C78D8 !important;
        color:#ffffff
      }
    }
  }
}