@import "../../../styles/variable";

.lz-container {
  position: relative;
}
.lz-table-container {
  position: relative;
  overflow: auto;
}
.lz-table-header {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1; 
}
.lz-table {
  width: 100%;
  border-collapse: collapse;
}
.lz-table th {
  padding: 6px !important;
  border: 1px solid #ddd;
  background-color: #cfd4da;
  text-align: center;
}
.lz-table td {
  padding: 6px !important;
  border: 1px solid rgb(228, 230, 239);
}
.position-relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.pointer {
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
.row-selected {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

//icon loading
#spinner {
  fill: transparent;
  stroke: $color-navy-blue;
  stroke-width: 8px;
  stroke-linecap: round;
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.4s;
  animation-timing-function: cubic-bezier(0.2, 0.5, 0.8, 1.1);
  animation-iteration-count: infinite;
}