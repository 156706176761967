.h-bang-ds-vat-tu-ton-kho {
  .table-responsive {
    max-height: max-content;
  }

  height: calc(100vh - 210px) !important;
}

.ton-kho-error {
  color: #FABE00 !important
}

.expired-hsd-error {
  color: #D81717 !important
}

.almost-expired-hsd-error {
  color: #870DF1 !important
}